import { useStaticQuery, graphql } from "gatsby"

export const useServices = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryServices {
        allContentfulClasesYTerapias {
          edges {
            node {
              buttonLeft
              buttonRight
              node_locale
              title
              description {
                description
              }
            }
          }
        }
      }
    `
  )
  return data.allContentfulClasesYTerapias.edges.map(i => ({
    lang: i.node.node_locale,
    title: i.node.title,
    buttonLeft: i.node.buttonLeft,
    buttonRight: i.node.buttonRight,
    description: i.node.description.description,
  }))
}
