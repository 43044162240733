import React, { useState } from "react"
import { CardVerticalLarge, CardVerticalTherapy } from "../elements/Cards"

import { useServices } from "../../utils/useServicesSection"
import { useLanguageContext } from "../../utils/LanguageContext"
import { useServicesCards } from "../../utils/useServicesCards"

const Services = () => {
  const [terapiasDisplay, setTerapiasDisplay] = useState("hidden")
  const [clasesDisplay, setClasesDisplay] = useState("block")

  const { toggle } = useLanguageContext()

  const data = useServices()
  const dataCards = useServicesCards()

  const langFilteredEs = data.filter(i => i.lang === "es")
  const langFilteredEn = data.filter(i => i.lang === "en")

  const cardFilteredEs = dataCards.filter(i => i.lang === "es")
  const cardFilteredEn = dataCards.filter(i => i.lang === "en")

  const handleClickClases = () => {
    setTerapiasDisplay("hidden")
    setClasesDisplay("block")
  }

  const handleClickTerapias = () => {
    setClasesDisplay("hidden")
    setTerapiasDisplay("block")
  }

  return (
    <div className="flex flex-col items-center  xl:items-start xl:flex-row md:pb-16">
      <div className="lg:w-1/3 flex flex-col items-center text-center lg:text-left lg:items-start mx-auto lg:mx-0">
        <h1 className="h1-mobile lg:hidden max-w-md lg:max-w-full">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <h1 className="h1-desktop hidden lg:block lg:pt-8">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <p className="py-4 max-w-md lg:max-w-full">
          {toggle === "es"
            ? langFilteredEs.map(i => i.description)
            : langFilteredEn.map(i => i.description)}
        </p>
        <div className="max-w-sm mt-4 mb-6 lg:flex inline">
          <button onClick={handleClickClases} className="main-button lg:mr-4">
            {toggle === "es"
              ? langFilteredEs.map(i => i.buttonLeft)
              : langFilteredEn.map(i => i.buttonLeft)}
          </button>
          <button
            onClick={handleClickTerapias}
            className="secondary-button mt-4 lg:mt-0"
          >
            {toggle === "es"
              ? langFilteredEs.map(i => i.buttonRight)
              : langFilteredEn.map(i => i.buttonRight)}
          </button>
        </div>
      </div>
      <div className="overflow-x-scroll overscroll-x-contain  xl:max-h-screen flex flex-col xl:flex-row xl:flex-wrap items-center xl:items-start justify-center sm:justify-around lg:justify-end w-full pb-2">
        {toggle === "es"
          ? cardFilteredEs.map(i =>
              i.category === "clase" ? (
                <div className={clasesDisplay}>
                  <CardVerticalLarge
                    title={i.title}
                    description={i.description_1}
                    duration={i.duration}
                    level={i.level}
                    image={i.image}
                    category={i.category}
                    id={i.id}
                  />
                </div>
              ) : (
                <div className={terapiasDisplay}>
                  <CardVerticalTherapy
                    title={i.title}
                    descriptionT={i.description_1}
                    duration={i.duration}
                    durationLong={i.durationLong}
                    image={i.image}
                    category={i.category}
                    id={i.id}
                  />
                </div>
              )
            )
          : cardFilteredEn.map(i =>
              i.category === "clase" ? (
                <div className={clasesDisplay}>
                  <CardVerticalLarge
                    title={i.title}
                    description={i.description_1}
                    duration={i.duration}
                    level={i.level}
                    image={i.image}
                    category={i.category}
                    id={i.id}
                  />
                </div>
              ) : (
                <div className={terapiasDisplay}>
                  <CardVerticalTherapy
                    title={i.title}
                    descriptionT={i.description_1}
                    duration={i.duration}
                    durationLong={i.durationLong}
                    image={i.image}
                    category={i.category}
                    id={i.id}
                  />
                </div>
              )
            )}
      </div>
    </div>
  )
}

export default Services
