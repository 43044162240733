import { useStaticQuery, graphql } from "gatsby"

export const useServicesCards = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryCardServices {
        allContentfulCardsClasesYTerapias {
          edges {
            node {
              id
              level
              category
              duration
              durationLong
              description_1 {
                description_1
              }
              node_locale
              title
              image {
                gatsbyImageData(height: 220, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    `
  )
  return data.allContentfulCardsClasesYTerapias.edges.map(i => ({
    lang: i.node.node_locale,
    title: i.node.title,
    duration: i.node.duration,
    durationLong: i.node.durationLong,
    level: i.node.level,
    description_1: i.node.description_1.description_1,
    image: i.node.image.gatsbyImageData,
    category: i.node.category,
    id: i.node.id,
  }))
}
