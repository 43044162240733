import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from "../components/sections/Services"

const ServicesPage = () => {
  return (
    <Layout>
      <Seo title="Clases y terapias" />
      <Services />
    </Layout>
  )
}

export default ServicesPage
